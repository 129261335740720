import React from "react"
import DarkLeaves from "./backgrounds/DarkLeaves"
import ContentSection from "./styles/ContentSection"
import CenteredDiv from "./styles/CenteredDiv"
import ThriveabilityLogo from "./styles/ThriveabilityLogo"

const SplashSection = () => {
  return (
    <ContentSection id="splash">
      <DarkLeaves overlayColor="charcoal" overlayOpacity="0.2">
        <CenteredDiv>
          <ThriveabilityLogo
            color="white"
            height="50vh"
            minHeight="300px"
            pulse
          />
        </CenteredDiv>
      </DarkLeaves>
    </ContentSection>
  )
}

export default SplashSection
