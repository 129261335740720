import React, { Component } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

class BackgroundSection extends Component {
  componentDidMount() {
    this.setState({
      mobile: window.innerWidth < 420,
    })
  }

  render() {
    const {
      className,
      children,
      imageData,
      overlayColor,
      overlayOpacity,
    } = this.props
    return (
      <BackgroundWrapper>
        {this.state && (
          <StyledBackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            backgroundColor={`#040e18`}
            mobile={this.state && this.state.mobile}
          >
            <Overlay
              overlayColor={overlayColor}
              overlayOpacity={overlayOpacity}
            />
            <ContentContainer>{children}</ContentContainer>
          </StyledBackgroundImage>
        )}
      </BackgroundWrapper>
    )
  }
}

const BackgroundWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  /* border: red 5px solid; */
  &:before {
    width: 100%;
    height: 100%;
    opacity: ${props => props.overlayOpacity || "0.4"};
    background: ${props => props.overlayColor || "white"};
    position: absolute;
    top: 0;
    left: 0;
  }
`
const StyledBackgroundImage = styled(BackgroundImage)`
  height: 100%;
  min-height: 100vh;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment: ${props => (props.mobile ? "scroll" : "fixed")};
`

const ContentContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* border: yellow 10px dashed; */
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  opacity: ${props => props.overlayOpacity || "0.4"};
  background: ${props => props.overlayColor || "white"};
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

export default BackgroundSection
