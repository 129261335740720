import React from "react"

import Page from "../components/Page"
import SEO from "../components/seo"
import AboutSection from "../components/AboutSection"
import SplashSection from "../components/SplashSection"

const IndexPage = () => (
  <Page>
    <SEO
      title="Thriveability"
      keywords={[
        "Environment",
        "Climate",
        "Conservation",
        "Recycle",
        "Sustainability",
      ]}
    />
    <SplashSection />
    <AboutSection />
  </Page>
)

export default IndexPage
