import React from "react"
import styled from "styled-components"
import ContentSection from "./styles/ContentSection"
import CenteredDiv from "./styles/CenteredDiv"

const StyledCopySection = styled.div`
  text-align: left;
  line-height: 5vh;
  padding: 4vw;
  opacity: 0.7;
  h2 {
    font-size: 15vw;
    line-height: 13vw;
    color: ${props => props.theme.colorOne};
    text-transform: uppercase;
  }
  p {
    font-size: 3vh;
    letter-spacing: 0.1vw;
  }
  br {
    line-height: 10vh;
  }
`

const AboutSection = () => {
  return (
    <ContentSection id="about">
      <CenteredDiv>
        <StyledCopySection>
          <h2>
            Create <br />
            Sustain <br />
            Thrive
          </h2>
          <p>
            As we further advance our technologies, improve perceptions around
            sustainable business practices, and understanding the urgency of
            rapidly reducing our global emissions, we must stop diluting the
            rhetoric. We can have a thriving economy and a thriving environment.
            Our organization is not working to sustain our current conditions,
            we are working to create a world where we can all thrive.
            <br />
            The technology is here, the policies have been introduced, the
            knowledge and information are readily available, so what is missing?
            A unified effort. A community that easily outlines what individual
            steps are important and how you personally can make an impact. A
            community that incentivises and rewards not only your personal
            sustainable behavior, but the collective as well. A community that
            creates transparency and accountability on the steps we all need to
            take for a better future.
            <br />
            We are creating a driven, impassioned, and well-organized effort to
            reduce our global footprint. Collectively, we must work together to
            grow this community, to create a movement that builds on one
            another's successes and establishes a unified effort towards a
            thriveable world.
          </p>
        </StyledCopySection>
      </CenteredDiv>
    </ContentSection>
  )
}

export default AboutSection
